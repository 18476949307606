import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import "../../assets/styles/dark.css";
import BannerLogoLight from "../../assets/images/InsureBox-banner.png";
import LogoLight from "../../assets/images/logo-light.png";
import { getAuthState } from "../../assets/scripts/login-util";

const SideMenu = (props) => {

  const [user, setUser] = useState(getAuthState('user'));


  useEffect(() => {
    $(".kt-menu__item").removeClass("kt-menu__item--active");
    $("#" + props.menuID).addClass("kt-menu__item--active");
  });

  const toggleTitle = () => {
    if ($("body").hasClass("kt-aside--minimize")) {

      $("#menuDashboard .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "Dashboard"
      );

      $("#menuUser-Stats .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "User-Stats"
      );

      $("#menuAppActions .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "App Actions"
      );

      $("#menuEmailer .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "Emailer"
      );
      $("#menuSubscription .kt-menu__link-icon .kt-svg-icon").attr(
        "data-original-title",
        "Subscription"
      );

      $(".kt-aside__brand-tools").addClass("aside-brand-tools");
      $("#asideBrandLogo").attr("src", LogoLight);
      $("#asideBrandLogo").attr("height", "30");
    } else {
    
      $(".kt-menu__link-icon .kt-svg-icon").removeAttr("data-original-title");
      $(".kt-aside__brand-tools").removeClass("aside-brand-tools");
      $("#asideBrandLogo").attr("src", BannerLogoLight);
      $("#asideBrandLogo").attr("height", "22");
    }
  };

  return (
    <div
      className="kt-aside kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
      <div className="kt-aside__brand kt-grid__item margin-b0" id="kt_aside_brand">
        <div className="kt-aside__brand-logo">
          <NavLink disabled={props.disabled} to="/">
            <img
              id="asideBrandLogo"
              alt="Logo"
              src={BannerLogoLight}
              height="22"
            />
          </NavLink>
        </div>
        <div className="kt-aside__brand-tools">
          <button
            onClick={() => toggleTitle()}
            className="kt-aside__brand-aside-toggler"
            id="kt_aside_toggler">
            <span>
              <svg className="nav-movment" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
                <path d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z" fill="currentColor" fillRule="evenodd">
                </path>
              </svg>
            </span>
            <span>
              <svg className="nav-movment margin-l65" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
                <path d="M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z" fill="currentColor" fillRule="evenodd">
                </path>
              </svg>
            </span>
          </button>
        </div>
      </div>

      <div
        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        id="kt_aside_menu_wrapper"
        >
        <div id="kt_aside_menu" className="kt-aside-menu  " data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500"
          >
          <ul style={{ paddingTop: "0px" }} className="kt-menu__nav ">
            <li id="menuDashboard" className="kt-menu__item margin-t15" aria-haspopup="true">
              <NavLink to="/dashboard" className="kt-menu__link ">
                <span data-original-title="Dashboard" className="kt-menu__link-icon">
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon"data-toggle="kt-tooltip"data-placement="right">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M10,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,7 C22,7.55228475 21.5522847,8 21,8 L10,8 C9.44771525,8 9,7.55228475 9,7 L9,5 C9,4.44771525 9.44771525,4 10,4 Z M10,10 L21,10 C21.5522847,10 22,10.4477153 22,11 L22,13 C22,13.5522847 21.5522847,14 21,14 L10,14 C9.44771525,14 9,13.5522847 9,13 L9,11 C9,10.4477153 9.44771525,10 10,10 Z M10,16 L21,16 C21.5522847,16 22,16.4477153 22,17 L22,19 C22,19.5522847 21.5522847,20 21,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,17 C9,16.4477153 9.44771525,16 10,16 Z" fill="#000000" />
                    <rect fill="#000000" opacity="0.3" x="2" y="4" width="5" height="16" rx="1" />
                  </g>
                </svg>
              </span>
              <span className="kt-menu__link-text">Dashboard</span>
              </NavLink>
            </li>
            <li id="menuUser-Stats" className="kt-menu__item" aria-haspopup="true">
              <NavLink
                to="/user-stats"
                className="kt-menu__link "> 
                <span  className="kt-menu__link-icon">
                  <svg width="24px"  height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                  <g stroke="none"  strokeWidth="1"  fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path 
                      d="M18,14 C16.3431458, 14 15,12.6568542 15, 11 C15, 9.34314575 16.3431458, 8 18,8 C19.6568542, 8 21, 9.34314575 21, 11 C21, 12.6568542 19.6568542, 14 18, 14 Z M9, 11 C6.790861, 11 5, 9.209139 5, 7 C5, 4.790861 6.790861, 3 9, 3 C11.209139, 3 13, 4.790861 13, 7 C13, 9.209139 11.209139, 11 9, 11 Z" 
                      fill="#000000" 
                      fillRule="nonzero" 
                      opacity="0.3"
                    />
                    <path 
                      d="M17.6011961, 15.0006174 C21.0077043, 15.0378534 23.7891749, 16.7601418 23.9984937, 20.4 C24.0069246, 20.5466056 23.9984937, 21 23.4559499, 21 L19.6, 21 C19.6, 18.7490654 18.8562935, 16.6718327 17.6011961, 15.0006174 Z M0.00065168429, 20.1992055 C0.388258525, 15.4265159 4.26191235, 13 8.98334134, 13 C13.7712164, 13 17.7048837, 15.2931929 17.9979143, 20.2 C18.0095879, 20.3954741 17.9979143, 21 17.2466999, 21 C13.541124, 21 8.03472472, 21 0.727502227, 21 C0.476712155, 21 -0.0204617505, 20.45918 0.00065168429, 20.1992055 Z" 
                      fill="#000000" 
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </span>
              <span className="kt-menu__link-text">User Stats</span>
              </NavLink>
            </li>
            {user && user.id === 1 &&(<>
              <li id="menuAppActions" className="kt-menu__item" aria-haspopup="true">
                <NavLink
                  to="/appActions"
                  className="kt-menu__link "> 
                  <span  className="kt-menu__link-icon">
                  <svg  width="24px"  height="24px"  viewBox="0 0 24 24"  version="1.1"  className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M7.5,4 L7.5,19 L16.5,19 L16.5,4 L7.5,4 Z M7.71428571,2 L16.2857143,2 C17.2324881,2 18,2.8954305 18,4 L18,20 C18,21.1045695 17.2324881,22 16.2857143,22 L7.71428571,22 C6.76751186,22 6,21.1045695 6,20 L6,4 C6,2.8954305 6.76751186,2 7.71428571,2 Z" fill="#000000" fillRule="nonzero"/>
                        <polygon fill="#000000" opacity="0.3" points="7.5 4 7.5 19 16.5 19 16.5 4"/>
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">App Actions</span>
                </NavLink>
              </li>
            </>)}
              <li id="menuEmailer" className="kt-menu__item" aria-haspopup="true">
                <NavLink to="/emailer/templates" className="kt-menu__link "> 
                  <span  className="kt-menu__link-icon">
                    <svg width="24px"  height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z" fill="#000000" opacity="0.3"/>
                        <path d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z" fill="#000000"/>
                      </g>
                    </svg>
                  </span>
                  <span className="kt-menu__link-text">Emailer</span>
                </NavLink>
              </li>
              <li id="menuSubscription" className="kt-menu__item" aria-haspopup="true">
                <NavLink to="/subscription" className="kt-menu__link "> 
                  <span  className="kt-menu__link-icon">
                    <svg width="24px"  height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <rect fill="#000000" opacity="0.3" x="2" y="5" width="20" height="14" rx="2"/>
                        <rect fill="#000000" x="2" y="8" width="20" height="3"/>
                        <rect fill="#000000" opacity="0.3" x="16" y="14" width="4" height="2" rx="1"/>
                      </g>
                    </svg>
                  </span>
                  <span className="kt-menu__link-text"> Subscription </span>
                </NavLink>
              </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
